import { translation } from 'common_constants/translation';
import InviteButton from '../Balance/BalanceNewButton';
import dayjs from 'dayjs';
import { CURRENCIES } from 'common_constants/business';
import './DebtContractItem.scss';

const DebtContractItem = ({ contract, payContractDebt, clientInfo, lang, disabled = false }) => {
  if (contract?.editPMonceAccess) return null;
  const contractName = contract?.rb
    ? 'Тривожна кнопка'
    : contract?.cn
    ? contract.cn
    : `№ ${contract?.contractIndex} (${dayjs(contract?.md).format('DD.MM.YYYY')})`;
  return (
    <div key={contract.contractId} className="debt-contract-item">
      <div className="debt-item">
        <div className="debt-content color-red title">{translation.debtName[lang]}</div>
        <div className="debt-content">
          {translation.debtContentContract[lang]} {contractName}
        </div>
        <div className="debt-content color-red title">
          {Math.abs(contract.remainingMoney)} {CURRENCIES[clientInfo.currency].symbol}
        </div>
        <InviteButton
          disabled={disabled}
          onClick={() => payContractDebt({ contract, clientInfo, lang })}
          buttonText={translation.payBtn[lang]}
          arrow={true}
        />
        <br />
      </div>
    </div>
  );
};

export default DebtContractItem;
