import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { CLIENT_LINK } from 'common_constants/routes';
import clsx from 'clsx';

import { BalanceNewButton } from '../../../components/Balance';
import QRCodeScanButton from '../../../components/QRCodeReader/QRCodeScanButton';
import { error, request } from '../../../tools';
import { Spin } from '../../../components';

import './InviteButtons.scss';

const InviteButtonsModal = ({ handleOpenScanner, setInviteState }) => {
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const [shareLink, setShareLink] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClipboardCopy = () => {
    setIsCopied(true);
  };

  const getInviteLinkToken = async () => {
    setIsLoading(true);

    await request(
      '/partners/generateInviteLinkToken',
      {},
      ({ inviteLinkToken }) => {
        setShareLink(`${CLIENT_LINK}/i/${inviteLinkToken}`);
      },
      error,
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (clientInfo?.inviteLinkToken) {
      setShareLink(`${CLIENT_LINK}/i/${clientInfo.inviteLinkToken}`);
      return;
    }

    getInviteLinkToken();
  }, []);

  return (
    <div className="invite-friend-share-link">
      <Spin spinning={isLoading}>
        <div className="">
          <div className="share-wrapper">
            <CopyToClipboard text={shareLink} onCopy={handleClipboardCopy}>
              <div className={clsx('copy-clipboard-link', isCopied ? '_copied' : '')}>
                <p>{shareLink}</p>
                <button className="copy-clipboard-btn">{isCopied ? <CheckOutlined /> : <CopyOutlined />}</button>
              </div>
            </CopyToClipboard>

            <div className="buttons-wrapper">
              <BalanceNewButton
                onClick={() => {
                  setInviteState(true);
                }}
                buttonText={translation.inviteFriend[lang]}
              />
              <QRCodeScanButton className="qr-link" onClick={handleOpenScanner} label={translation.scanQRcode?.[lang] ?? ''} />
            </div>

            <div className="share-links-wrapper">
              <FacebookShareButton url={shareLink}>
                <FacebookIcon className="share-soc-link-icon" size={40} round />
              </FacebookShareButton>
              <TelegramShareButton url={shareLink}>
                <TelegramIcon className="share-soc-link-icon" size={40} round />
              </TelegramShareButton>
              <TwitterShareButton url={shareLink}>
                <TwitterIcon className="share-soc-link-icon" size={40} round />
              </TwitterShareButton>
              <ViberShareButton url={shareLink}>
                <ViberIcon className="share-soc-link-icon" size={40} round />
              </ViberShareButton>
              <WhatsappShareButton url={shareLink}>
                <WhatsappIcon className="share-soc-link-icon" size={40} round />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default InviteButtonsModal;
