import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, NiceBtn } from 'common_components';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { setModal, editHotline } from '../../store/commonReducer';
import Modal from '../../components/Modal';
import { Button } from '../../components';
import { translation } from 'common_constants/translation';
import { request, info, error, success } from '../../tools';
import { ADD_CLIENT_EMAIL, FIRST_LOGIN_AUTO_PAY } from 'common_constants/modals';
import './styles.scss';

const QuestionnaireHotlineAdvertising = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { transaction } = useSelector((state) => state.common.modal) || {};
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);
  const currentFillia = useSelector((state) => state.common.filii?.find((fil) => fil.name === transaction?.filName));
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const [fbLeft, setFbLeft] = useState(false);

  const handleChainFirstModals = () => {
    if (missingEmail) {
      dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
    } else if (sessionStorage.getItem('modalFirst') !== 'true') {
      dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
    } else {
      dispatch(setModal());
    }
  };

  const handleCancel = () => {
    info('', translation.questionnaireArchiveAdvertisingInfo[lang]);
    handleChainFirstModals();
  };

  const handleClick = () => {
    dispatch(setModal());
    history.push(CLIENT_ROUTES.INVITE_FRIEND.route);
  };

  const handleFeedbackClick = () => {
    setFbLeft(true);
    const feedbackData = {
      fbBonus: true,
      hotlineId: transaction.H,
      clientId: clientInfo._id,
      B: clientInfo.B,
    };
    const updateData = { fbBonus: true, _id: transaction.H };

    const handleSuccess = () => {
      dispatch(editHotline(updateData));
      success('Бонуси успішно зараховані');
    };

    const handleError = () => {
      setFbLeft(false);
      error('Бонусна транзакція не пройшла, спробуйте ще раз');
    };

    request('/hotline/gettingFeedbackBonus', feedbackData, handleSuccess, handleError);
    window.open(currentFillia?.feedback);
  };

  return (
    <Modal className="questionnaire-archive-advertising" title="" open onCancel={handleCancel} footer={null}>
      <Box className="advertising-box">
        {translation.questionnaireArchiveAdvertisingText1[lang]}
        {!fbLeft ? (
          <div className="feedback-section">
            <b>
              Залиште відгук, та отримайте <span className="bonus-amount">500 грн</span> на бонусний рахунок!
            </b>
            <Button className="feedback-button" onClick={handleFeedbackClick}>
              Погодитись
            </Button>
          </div>
        ) : (
          <p>
            <b>Вітаємо! Ви отримали 500 грн на бонусний рахунок.</b>
          </p>
        )}
      </Box>
      <b>{translation.questionnaireArchiveAdvertisingText2[lang]}</b>
      <br />
      <br />
      <b>
        {translation.questionnaireArchiveAdvertisingText3[lang]} <span className="bonus-amount">300 {translation.currency[lang]}</span>{' '}
        {translation.questionnaireArchiveAdvertisingText3_1[lang]}
      </b>
      <br />
      <br />
      {translation.questionnaireArchiveAdvertisingText4_1[lang]} <b>{translation.questionnaireArchiveAdvertisingText4_2[lang]}</b>{' '}
      {translation.questionnaireArchiveAdvertisingText3[lang]} <b>{translation.questionnaireArchiveAdvertisingText4_4[lang]}</b>{' '}
      {translation.questionnaireArchiveAdvertisingText4_5[lang]}
      <br />
      <br />
      <b>{translation.questionnaireArchiveAdvertisingText5[lang]}</b>
      <br />
      <br />
      <div className="video_container">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/-6uU4WrKsIk?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <Box className="center-box">
        <NiceBtn onClick={handleClick}>{translation.learnMoreBtn[lang]}</NiceBtn>
      </Box>
    </Modal>
  );
};

export default QuestionnaireHotlineAdvertising;
