import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { translation } from 'common_constants/translation';
import { ROUTES } from 'common_constants/routes';

import Alert from '../../../../components/Alert/Alert';
import { Button } from '../../../../components';

export default function AppointmentForToday({ appointmentForToday, data, lang, getCurrentFilForAlert }) {
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.CLIENT_MY_HOTLINES);
  };

  if (!appointmentForToday) return null;

  return (
    <Alert
      message={`${translation.clientHotline.youHaveAnAppointment[lang]} ${dayjs(appointmentForToday.date).format('DD.MM.YYYY')}`}
      description={<Button onClick={handleClick}>Перейти до консультації </Button>}
      type="info"
    />
  );
}
