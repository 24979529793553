import { useState, useEffect, useRef } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { translation } from 'common_constants/translation';
import { PAY_QUICKCHAT, CLUB_CARDS_TYPES, PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import Message from '../../components/Chat/Message';
import Spin from '../../components/Spin/Spin';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button';
import Input from '../../components/InputNew/InputNew';
import PaymentQuickChat from '../../Modals/PaymentQuickChat/PaymentQuickChat';
import reactNativeService from '../../tools/reactNativeService';
import aiAssistantImg from "../../images/woman-ai-assistant.png"
import { CheckClientInfo } from '../../tools/hooks';
import { editClientInfo, setModal, setQuickChatLoader } from '../../store/commonReducer';
import { success, error, request } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import { ReactComponent as Pen } from '../../images/pen.svg';
import './QuickChat.scss';
import { INFORMATION_TYPES } from 'common_constants/ui';

const QuickChat = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sendLoader, setSendLoader] = useState(false);
  const [questionLoader, setQuestionLoader] = useState(false);
  const [freeQuestion, setFreeQuestion] = useState(5);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState();
  const [clientPaid, setClientPaid] = useState(false);
  const [showBuyButton, setShowBuyButton] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [payModalLoading, setPayModalLoading] = useState();
  const [threadId, setThreadId] = useState('');
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { emailVerified, phoneVerified } = clientAuth;
  const quickChatQuestions = useSelector((state) => state.common.quickChatQuestions);
  const quickChatLoader = useSelector((state) => state.common.quickChatLoader);
  const lang = useSelector((state) => state.common.language);
  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  useEffect(() => {
    dispatch(setMainLoader(true));
    countQuestions();
    createThread();
    dispatch(setMainLoader(false));
  }, []);

  useEffect(() => {
    if (quickChatQuestions !== -1) {
      setFreeQuestion((prev) => prev + quickChatQuestions);
    }
  }, [quickChatQuestions]);

  useEffect(() => {
    if (!quickChatLoader) {
      setQuestionLoader(quickChatLoader);
      dispatch(setQuickChatLoader(true));
    }
  }, [quickChatLoader]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    isSentByCurrentUser: true,
    date: new Date(),
    name: clientInfo.n,
    role: 'user',
  });

  const makeAssistantMessage = (text) => ({
    message: text,
    isSentByCurrentUser: false,
    date: new Date(),
    name: translation.assistant[lang],
    role: 'assistant',
  });

  const handleSend = async () => {
    if (inputValue === '') return;

    setInputValue('');
    setSendLoader(true);

    setMessages((prev) => [...prev, makeMyMessage(inputValue)]);

    await sendMessage(inputValue);

    let errorOccurred;
    let lastMessage;
    do {
      errorOccurred = false;
      try {
        const run = await runsAssistant();

        let status = await checkAssistantRuns(run);

        while (status === 'in_progress' || status === 'queued') {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          status = await checkAssistantRuns(run);
        }

        lastMessage = await getLastMessage(run);
      } catch (error) {
        errorOccurred = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (errorOccurred);

    if (lastMessage) {
      if (freeQuestion === 1) {
        setQuestionLoader(true);
      }

      setFreeQuestion((prev) => prev - 1);
      cache.current.clearAll();
      setSendLoader(false);
      setMessages((prev) => [...prev, makeAssistantMessage(lastMessage)]);
      dispatch(editClientInfo({ clientQuickChatLength: clientInfo.clientQuickChatLength + 2 }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const countQuestions = () => {
    request('/quickChat/countQuestions', { userId: clientAuth._id }, ({ countQuestions, numberPurchasedQuestions }) => {
      setFreeQuestion(countQuestions);
      setClientPaid(!!numberPurchasedQuestions);
      setQuestionLoader(false);
      if (numberPurchasedQuestions) {
        const selectedOption = PAY_QUICKCHAT.find((option) => option.value === numberPurchasedQuestions);
        setSelectedPaymentIndex(selectedOption.index);
      }
    });
  };

  const createThread = () => {
    let errorOccurred;
    do {
      errorOccurred = false;
      try {
        request('/quickChat/createThread', {}, (res) => {
          setThreadId(res.threadId);
        });
      } catch (error) {
        errorOccurred = true;
      }
    } while (errorOccurred);
  };

  const runsAssistant = async () => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');

      const headers = { Authorization: `Bearer ${token}` };

      // todo: use our request method
      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/runAssistant`,
        { threadId: threadId },
        {
          headers: headers,
        },
      );

      return response.data.runId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendMessage = async (message) => {
    await request('/quickChat/sendMessage', { threadId: threadId, message: message, userId: clientAuth._id }, () => {}, error);
  };

  const checkAssistantRuns = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/checkAssistantRun`,
        { threadId: threadId, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.contour;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getLastMessage = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/quickChat/getLastMessage`,
        { threadId: threadId, userId: clientAuth._id, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.lastMessage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (freeQuestion === 0 && !clientPaid) {
      setShowBuyButton(true);
    }

    if (clientPaid && freeQuestion === 0) {
      dispatch(setMainLoader(true));
      handlePay(selectedPaymentIndex);
      dispatch(setMainLoader(false));
    }
  }, [freeQuestion]);

  const handleBuyQuestion = () => {
    setModalVisible(true);
    CheckClientInfo(dispatch);
  };

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handlePay = (selectedPaymentOption) => {
    setPayModalLoading(true);
    const selectedOption = PAY_QUICKCHAT.find((option) => option.index === selectedPaymentOption);
    const body = {
      userId: clientAuth._id,
      amount: selectedOption.amount,
      value: selectedOption.value,
      currency: currentCurrency,
    };
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          if (!clientPaid) {
            setModalVisible(false);
            setShowBuyButton(false);
          }
          countQuestions();
          CheckClientInfo(dispatch);
          success(translation.quickQuestionsBuyed[lang]);
          setPayModalLoading(false);
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              if (!clientPaid) {
                setModalVisible(false);
                setShowBuyButton(false);
              }
              countQuestions();
              CheckClientInfo(dispatch);
              success(translation.quickQuestionsBuyed[lang]);
              setPayModalLoading(false);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
              setPayModalLoading(false);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };
    const onError = (_, __, axiosError) => error('Помилка оплати!', axiosError.error);
    request('/payment/mono/quickChat/link', body, onSuccess, onError);
  };

  return (
    <div className="quick-chat-page">
      {emailVerified && phoneVerified ? (
        <div className="quick-chat-page-wrapper">
          <Button className="buy-question-btn" onClick={handleBuyQuestion}>
            {translation.buyQuickQuestion[lang]}
          </Button>
          {freeQuestion === 0 && !clientPaid ? (
            <Alert
              message={translation.alert[lang]}
              description={translation.noFreeQuestions[lang]}
              type={INFORMATION_TYPES.WARNING}
              showIcon
              closable
              banner={true}
            />
          ) : null}

          {!clientInfo.clientQuickChatLength ? (
            <Alert
              message={translation.alert[lang]}
              description={translation.warning[lang]}
              type={INFORMATION_TYPES.WARNING}
              showIcon
              closable
              banner={true}
            />
          ) : null}

          {showBuyButton && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
              <Button onClick={handleBuyQuestion}>{translation.buyQuickQuestion[lang]}</Button>
            </div>
          )}
          <PaymentQuickChat
            open={modalVisible}
            onCancel={handleCancelModal}
            onPay={handlePay}
            paymentIndex={setSelectedPaymentIndex}
            payModalLoading={payModalLoading}
          />
          <div className="quick-chat-page-chat-container">
            <div className="quick-chat-page-chat-container-list">
              {messages.length === 0 && (
                <div className='quick-chat-page-chat-container-list-intro-message'>
                  <div className='quick-chat-page-chat-container-list-intro-message-image' style={{ backgroundImage: `url(${aiAssistantImg})`}}/>
                  <div className='quick-chat-page-chat-container-list-intro-message-title'>Задайте своє питання!</div>
                  <p>А наш асистент на базі штучного інтелекту допоможе вам знайти найкращу відповідь.</p>
                </div>
              )}
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    width={width}
                    height={height}
                    rowHeight={cache.current.rowHeight}
                    deferredMeasurementCache={cache.current}
                    rowCount={messages.length}
                    scrollToIndex={messages.length - 1}
                    overscanRowCount={0}
                    rowRenderer={({ key, index, style, parent }) => {
                      const message = messages[index];
                      return (
                        <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                          <div style={style}>
                            <Message item={message} onDownload={() => {}} />
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
            {sendLoader && (
              <div className="quick-chat-page-chat-container-list-response-status">
                <span>{translation.answerQuickQuestion[lang]}</span>
                <Pen className="quick-chat-page-chat-container-list-response-status-pen-icon" />
              </div>
            )}
            <div className="quick-chat-page-input-container">
              {questionLoader ? (
                <Spin className="quick-chat-page-input-container-spin" />
              ) : (
                <span className="quick-chat-page-input-container-counter">
                  {clientInfo?.clubCard?.cardType === CLUB_CARDS_TYPES.PLATINUM ? <span>&infin;</span> : freeQuestion}
                </span>
              )}

              <Input
                placeholder={translation.inputQuickQuestion[lang]}
                value={inputValue}
                onChange={handleInput}
                onKeyDown={handleKeyDown}
                disabled={freeQuestion === 0 || sendLoader}
                className="quick-chat-page-input-container-input"
              />
              {sendLoader ? (
                <Spin className="quick-chat-page-input-container-spin" />
              ) : (
                <button onClick={handleSend} className="quick-chat-page-input-container-button">
                  <SendOutlined className="quick-chat-page-input-container-icon" />
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1 style={{ textAlign: 'center' }}>{translation.quickQuestionTitle1[lang]}</h1>
          {/* Removed AddClientEmail to prevent double rendering */}
        </>
      )}
    </div>
  );
};

export default QuickChat;
