import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined } from '@ant-design/icons';

import { translation } from 'common_constants/translation';
import { CLIENT_ROUTES } from 'common_constants/routes';
import AddClientEmail from '../../Modals/AddClientEmail/AddClientEmail';
import Modal from '../../components/Modal';
import { Button, Row } from '../../components';
import { setMainLoader } from '../../store/uiReducer';
import SuccessMessage from './SuccessMessage';
import Step1Client from './Step1Client';

import './ClientHotline.scss';

const ClientHotline = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token: friendShareLinkToken } = useParams();

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const activeFilii = useSelector((state) => state.common.activeFilii);
  const filii = useSelector((state) => state.common.filii);
  const topFilials = useSelector((state) => state.common.topFilials);
  const lang = useSelector((state) => state.common.language);

  const { emailVerified, phoneVerified } = clientAuth;

  const [data, setData] = useState({
    type: '0',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isHotlineAdded, setIsHotlineAdded] = useState(false);
  const [clientHotlineInfo, setClientHotlineInfo] = useState();

  const success = () => setModalOpen(true);

  const setLoading = (loadingState) => {
    dispatch(setMainLoader(loadingState));
  };

  const modalClose = () => {
    setModalOpen(false);
    history.push(CLIENT_ROUTES.CLIENT_HOME.route);
  };

  useEffect(() => {
    if (friendShareLinkToken) {
      localStorage.setItem('friendShareLinkToken', friendShareLinkToken);
    }
  }, [friendShareLinkToken]);

  useEffect(() => {
    if (isHotlineAdded) {
      success();
    }
  }, [isHotlineAdded]);

  return (
    <div className="clientHotline_page">
      <Modal
        open={modalOpen}
        title={
          <>
            <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 24 }} /> Запис успішно створено!
          </>
        }
        closable={false}
      >
        <div className="clientHotline_page__modal_content">
          <SuccessMessage clientHotlineInfo={clientHotlineInfo} lang={lang} />
        </div>
        <Row justify={'end'}>
          <Button onClick={modalClose} style={{ width: 'auto', height: 'auto', padding: '2px 6px' }}>
            {translation.clientHotlineSubmitButton[lang]}
          </Button>
        </Row>
      </Modal>
      <div className="card">
        {emailVerified && phoneVerified ? (
          <Step1Client
            data={data}
            activeFilii={activeFilii}
            filii={filii}
            clientInfo={clientInfo}
            token={window.localStorage.getItem('clientAccessToken')}
            host={process.env.REACT_APP_API}
            setIsHotlineAdded={setIsHotlineAdded}
            setLoading={setLoading}
            setClientHotlineInfo={setClientHotlineInfo}
            setData={setData}
            lang={lang}
            topFilials={topFilials}
          />
        ) : (
          <>
            <h1 style={{ textAlign: 'center' }}>{translation.clientHotlineTitle[lang]}</h1>
            <AddClientEmail />
          </>
        )}
      </div>
    </div>
  );
};

export default ClientHotline;
