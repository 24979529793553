import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalWindow from '../ModalWindow';
import { request, error, success, warn } from '../../tools';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import { editContracts, setContract } from '../../store/commonReducer';

import { translation } from 'common_constants/translation';
import './FeedbackBonusModal.scss';

const FeedbackBonusModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const filii = useSelector((state) => state.common.filii);
  const contract = useSelector((state) => state.common.contract);
  const [fbBonus, setfbBonus] = useState(contract?.fbBonus ? contract.fbBonus : false);

  const findFil = (fil) => fil.i === contract?.f;
  const currentFilli = filii ? filii.find(findFil) : [];

  const handleClick = () => {
    if (fbBonus) return;
    setfbBonus(true);

    const getFBBonus = {
      fbBonus: true,
      contractId: contract._id,
      clientInId: clientInfo._id,
      B: clientInfo.B,
    };
    const updateData = {
      fbBonus: true,
      _id: contract._id,
    };

    const onSuccess = () => {
      dispatch(editContracts(updateData));
      success('Бонуси успішно зараховані');
    };
    const onError = () => {
      error('Бонусна транзакція не пройшла, спробуйте ще раз');
    };

    request('/contracts/gettingFeedbackBonus', getFBBonus, onSuccess, onError);

    window.open(currentFilli?.feedback);
  };

  return (
    <ModalWindow modal={open} setModal={setOpen} title={fbBonus ? 'Вітаємо!' : 'Залиште відгук, та отримайте бонус!'}>
      {fbBonus ? (
        <div className={`feedbackBonus-modal `}>
          <p className="feedbackBonus-text">Ви отримали 1000 грн на бонусний рахунок</p>
        </div>
      ) : (
        <div className={`feedbackBonus-modal `}>
          <p className="feedbackBonus-text">
            Опишіть свої враження від роботи з нами у відгуках в Google та отримайте <b>1000 грн</b> на бонусний рахунок.
          </p>
          <div className="buttons">
            <button type="button" className="button-not" onClick={() => setOpen(false)}>
              {translation.notNowBtn[lang]}
            </button>
            <BalanceNewButton disabled={loading} onClick={handleClick} buttonText="Погодитись" arrow={false} />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

export default FeedbackBonusModal;
