import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionName from '../../components/SectionName';
import { translation } from 'common_constants/translation';
import { REGISTER_PRICE } from 'common_constants/business';
import { setMainLoader } from '../../store/uiReducer';
import { error, request, success } from '../../tools';
import { renderList } from '../../tools/registerHepler';
import { ClientBalancePayModal } from '../../components';
import { setClientBalance } from '../../store/commonReducer';
import InputNew from '../../components/InputNew';
import Button from '../../components/Button';

import './RegisterSelfSearch.scss';

const RegisterSelfSearch = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { clientBalance } = clientInfo;
  const [filter, setFilter] = useState({
    alimony: false,
    corruptioner: false,
    wanted: false,
  });
  const [showResults, setShowResults] = useState(false);
  const [currentSearchName, setCurrentSearchName] = useState(clientInfo.n);
  const [showStatus, setShowStatus] = useState({
    alimony: false,
    corruptioner: false,
    wanted: false,
  });
  const [alimonyList, setAlimonyList] = useState([]);
  const [corruptionerList, setCorruptionerList] = useState([]);
  const [wantedList, setWantedList] = useState([]);

  const [showPayModal, setShowPayModal] = useState(false);
  const [price, setPrice] = useState();

  const handleClickSearch = async (event) => {
    event.preventDefault();
    dispatch(setMainLoader(true));
    const hasAnyRegister = Object.values(filter).some((value) => value === true);
    if (!hasAnyRegister) {
      error(translation.error[lang], translation.registerError.selectRegisters[lang]);
      dispatch(setMainLoader(false));
      return;
    }
    if (clientBalance < price) {
      error(translation.registerError.notEnoughFunds[lang], translation.registerError.topUpYourBalance[lang]);
      setShowPayModal(true);
      dispatch(setMainLoader(false));
      return;
    }

    if (filter.alimony) {
      await request(
        `/register/alimony/search`,
        {
          searchQuery: currentSearchName,
        },
        (res) => {
          console.log();

          setAlimonyList(res.data.data || []);
          setShowResults(true);
          setShowStatus({
            alimony: filter.alimony,
            corruptioner: filter.corruptioner,
            wanted: filter.wanted,
          });
          dispatch(setClientBalance(clientBalance - price));
          success(translation.successMessage.alimony[lang], translation.successMessage.base[lang]);
        },
        (_, __, axiosError) => {
          error(translation.error[lang], axiosError?.error);
          dispatch(setMainLoader(false));
        },
      );
    }

    if (filter.corruptioner) {
      await request(
        `/register/corruptioner/search`,
        {
          searchQuery: currentSearchName,
        },
        (res) => {
          setCorruptionerList(res.data.data || []);
          setShowResults(true);
          setShowStatus({
            alimony: filter.alimony,
            corruptioner: filter.corruptioner,
            wanted: filter.wanted,
          });
          dispatch(setClientBalance(clientBalance - price));
          success(translation.successMessage.corruptioner[lang], translation.successMessage.base[lang]);
        },
        (_, __, axiosError) => {
          error(translation.error[lang], axiosError?.error);
          dispatch(setMainLoader(false));
        },
      );
    }

    if (filter.wanted) {
      await request(
        `/register/wanted/search`,
        {
          searchQuery: currentSearchName,
        },
        (res) => {
          setWantedList(res.data.data || []);
          setShowResults(true);
          setShowStatus({
            alimony: filter.alimony,
            corruptioner: filter.corruptioner,
            wanted: filter.wanted,
          });
          dispatch(setClientBalance(clientBalance - price));
          success(translation.successMessage.wanted[lang], translation.successMessage.base[lang]);
        },
        (_, __, axiosError) => {
          error(translation.error[lang], axiosError?.error);
          dispatch(setMainLoader(false));
        },
      );
    }

    dispatch(setMainLoader(false));
  };

  const handleClickCkeckbox = (event) => {
    const register = event.target.dataset.type;
    setFilter((prev) => ({
      ...prev,
      [register]: !prev[register],
    }));
  };

  const handleSearchChange = (e) => {
    setCurrentSearchName(e.currentTarget.value);
  };
  const onClickSearchBtn = (e) => {
    setCurrentSearchName(clientInfo.n);
  };

  useEffect(() => {
    const count = Object.values(filter);
    const price = count.reduce((acc, current) => (current ? acc + REGISTER_PRICE : acc), 0);
    setPrice(price);
  }, [filter]);

  return (
    <div className="registerSelfSearch">
      <SectionName text={clientInfo.n} />
      <section className="findMe">
        <div className="titleInner">
          <span className="title">{translation.registerTitles.search.alimony[lang]}</span>
        </div>
        <div className="search-wrapper">
          <div className="currentSearchNameInput">
            <InputNew value={currentSearchName} onChange={handleSearchChange} />
          </div>
          <Button onClick={onClickSearchBtn} className="onClickSearchBtn">
            Знайти себе
          </Button>
        </div>
        <ul className="list">
          <li className="registerRow">
            <span className="label">{translation.registerTitles.alimonyDebtors[lang]}</span>
            <div data-type="alimony" onClick={handleClickCkeckbox} className={`checkbox ${filter.alimony ? 'checked' : null} `}>
              <span data-type="alimony"></span>
              <span data-type="alimony"></span>
            </div>
          </li>
          <li className="registerRow">
            <span className="label">{translation.registerTitles.registerOfCorruptioners[lang]}</span>
            <div data-type="corruptioner" onClick={handleClickCkeckbox} className={`checkbox ${filter.corruptioner ? 'checked' : null} `}>
              <span data-type="corruptioner"></span>
              <span data-type="corruptioner"></span>
            </div>
          </li>
          <li className="registerRow">
            <span className="label">{translation.registerTitles.wantedPersons[lang]}</span>
            <div data-type="wanted" onClick={handleClickCkeckbox} className={`checkbox ${filter.wanted ? 'checked' : null} `}>
              <span data-type="wanted"></span>
              <span data-type="wanted"></span>
            </div>
          </li>
        </ul>
        <div className="info">
          <span>
            {translation.balance[lang]}: {clientBalance} {translation.currencyUah[lang]}
          </span>
          <span>
            {translation.registerTitles.priceForOneRegister[lang]}: {REGISTER_PRICE} {translation.currencyUah[lang]}
          </span>
        </div>
        <div className="total">
          <span>{translation.registerTitles.totalAmount[lang]}:</span>
          <span>
            {price} {translation.currencyUah[lang]}
          </span>
        </div>
        <input
          onClick={handleClickSearch}
          className="button"
          type="button"
          value={`${translation.registerTitles.searchBtn[lang]} ${price} ${translation.currencyUah[lang]}`}
        />
        {showResults ? (
          <div className="results">
            {showStatus.alimony && <h3 className="registerName">{translation.registerTitles.alimonyDebtors[lang]}</h3>}
            {showStatus.alimony && <ul className="list">{renderList(alimonyList, translation.registerOpts['alimony'], lang)}</ul>}
            {showStatus.corruptioner && <h3 className="registerName">{translation.registerTitles.registerOfCorruptioners[lang]}</h3>}
            {showStatus.corruptioner && <ul className="list">{renderList(corruptionerList, translation.registerOpts['corruptioner'], lang)}</ul>}
            {showStatus.wanted && <h3 className="registerName">{translation.registerTitles.wantedPersons[lang]}</h3>}
            {showStatus.wanted && <ul className="list">{renderList(wantedList, translation.registerOpts['wanted'], lang)}</ul>}
          </div>
        ) : null}
      </section>
      <ClientBalancePayModal
        arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
        open={showPayModal}
        setOpen={setShowPayModal}
        title={translation.replenishmentBalance[lang]}
        placeholder={translation.clientBalancePayModalPlaceholder[lang]}
        buttonText={translation.clientBalancePayModalButtonText[lang]}
      />
    </div>
  );
};

export default RegisterSelfSearch;
