import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';

import { setModal, setContractChat } from '../../store/commonReducer';
import { request, requestFile, error, postFile } from '../../tools';
import Chat from '../../components/Chat';
import { translation } from 'common_constants/translation';

import './styles.scss';

const ChatForContract = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const clientAuth = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.usersData);
  const contractChats = useSelector((state) => state.common.contractChats);
  const socket = useSelector((state) => state.common.socket);

  const findCurrentChat = contractChats?.find((item) => item.chatId === data.contractId) || null;

  const onSendMessage = (params = {}) => {
    if (!params.message) return error(translation.chatForContractError1[lang]);
    if (typeof params.callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    request(
      '/chatPrivate/chatSendMessageClient',
      { documentId: data.contractId, message: params.message, clientName: clientAuth.n, fromSocketId: socket?.id },
      (req) => {
        params.callback();
      },
      error,
    );
  };

  const onSendFiles = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error(translation.chatForContractError2[lang]);
    if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    formData.append('documentId', data.contractId);
    formData.append('userId', clientAuth._id);
    formData.append('clientName', clientAuth.n);
    formData.append('fromSocketId', socket?.id);

    postFile('/chatPrivate/chatSendFilesClient', formData, callback, onError);
  };

  const onGetFile = (params = {}) => {
    const { fileId, fileName, callback, onError } = params;

    if (!fileId) return error(translation.chatForContractError3[lang]);
    // if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    requestFile('/chatPrivate/chatGetFileClient', { fileId, fileName, documentId: data.contractId }, callback, onError);
  };

  const onGetMessages = (callback) => {
    if (typeof callback !== 'function') return error(translation.chatForContractErrorCallback[lang]);

    if (findCurrentChat) {
      callback(
        findCurrentChat?.messages?.map((item) => ({
          ...item,
          isSentByCurrentUser: item.sender === clientAuth._id,
          name:
            item.sender === clientAuth._id
              ? clientAuth.n
              : users?.find((user) => user._id === item.sender)?.name || translation.chatForContractError4[lang],
        })) ?? [],
      );
      return;
    }

    request(
      '/chatPrivate/getchatClient',
      { documentId: data.contractId },
      (req) => {
        dispatch(setContractChat({ chatId: data.contractId, data: req.data }));
        callback(
          req.data?.map((item) => ({
            ...item,
            isSentByCurrentUser: item.sender === clientAuth._id,
            name:
              item.sender === clientAuth._id
                ? clientAuth.n
                : users?.find((user) => user._id === item.sender)?.name || translation.chatForContractError4[lang],
          })) ?? [],
        );
      },
      error,
    );
  };

  if (!data.contractId) {
    error(translation.chatForContractError5[lang]);
    return null;
  }

  return (
    <Modal open title={data.contractTitle} onCancel={() => dispatch(setModal())} footer={false}>
      <Chat
        title={data.contractTitle}
        myName={clientAuth.n}
        onSendMessage={onSendMessage}
        onSendFile={onSendFiles}
        onGetMessages={onGetMessages}
        onGetFile={onGetFile}
        sendFileDirectly={data.sendFileDirectly}
        chatId={data?.contractId}
      />
    </Modal>
  );
};

export default ChatForContract;
