import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from 'common_components';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import Spin from '../../components/Spin';
import Space from '../../components/Space';
import TextArea from '../../components/Textarea';
import SearchSelect from '../../components/SearchSelect';

import { setModal } from '../../store/commonReducer';
import { request, warn, info, error } from '../../tools';

import { DECIMAL_RATING_TO_CLIENT, LOGICAL_STANDARD_CHOISE_4_OPTIONS } from 'common_constants/business/index';
import { translation } from 'common_constants/translation';
import { ADD_CLIENT_EMAIL, FIRST_LOGIN_AUTO_PAY } from 'common_constants/modals';

import './QuestionnaireInterimServiceQualityAssessment.scss';

const QuestionnaireInterimServiceQualityAssessment = () => {
  const dispatch = useDispatch();

  const contracts = useSelector((state) => state.common.contracts);

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);

  const { Text, Title } = Typography;

  const [loading, setLoading] = useState(false);
  const [desError, setDesError] = useState('');

  const [contractsData, setContractsData] = useState(
    contracts
      ?.filter((contract) => !contract.ad)
      .map((contract) => ({
        i: contract.i,
        id: contract.contractId,
        sq: undefined,
        sc: undefined,
        ts: undefined,
        pc: '',
      })) || [],
  );

  const showFirstModals = () => {
    if (missingEmail) {
      dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
    } else if (sessionStorage.getItem('modalFirst') !== 'true') {
      dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
    } else {
      dispatch(setModal());
    }
  };

  const handleTextChange = (e, index) => {
    const newContractsData = [...contractsData];
    newContractsData[index].pc = e.target.value;
    const error = e.target.value.length < 20 && e.target.value;
    setDesError(error ? 'error' : '');
    setContractsData(newContractsData);
  };

  const handleSelectChange = (value, field, index) => {
    const newContractsData = [...contractsData];
    newContractsData[index][field] = value;
    setContractsData(newContractsData);
  };

  const validateForm = () => {
    const errorMessages = {
      clientInfo: translation.questionnaireArchiveWarn1[lang],
      sq: translation.questionnaireArchiveWarn2[lang],
      sc: translation.questionnaireArchiveWarn4[lang],
      ts: translation.questionnaireArchiveWarn7[lang],
      pc: translation.questionnaireArchiveWarn8[lang],
      pcShort: translation.questionnaireArchiveWarn9[lang],
    };

    if (!clientInfo._id) return warn('', errorMessages.clientInfo);

    for (const contract of contractsData) {
      if (contract.sq === undefined || contract.sq === '') return warn('', errorMessages.sq);
      if (contract.sc === undefined || contract.sc === '') return warn('', errorMessages.sc);
      if (contract.ts === undefined || contract.ts === '') return warn('', errorMessages.ts);
      if (contract.pc === undefined || contract.pc === '' || contract.pc.length < 20)
        return warn('', contract.pc.length < 20 ? errorMessages.pcShort : errorMessages.pc);
    }

    return true;
  };
  const onSubmit = () => {
    if (!validateForm()) return;

    setLoading(true);

    contractsData.forEach((contract) => {
      const data = {
        sq: contract.sq,
        sc: contract.sc,
        ts: contract.ts,
        pc: contract.pc,
        clientId: clientInfo._id,
        contractIndex: contract.i,
        contractId: contract.id,
      };
      request(
        '/questionnaires/saveInterimServiceQualityAssessment',
        data,
        (req) => {
          info('', translation.questionnaireSuccess[lang]);
          setLoading(false);
          showFirstModals();
        },
        () => {
          error('', translation.questionnaireError[lang]);
          setLoading(false);
        },
      );
    });
  };

  const onCancel = () => {
    info('', translation.QuestionnaireInfo[lang]);
    showFirstModals();
  };

  return (
    <Modal
      open
      className="questionnaire-quality-assessment"
      title={
        <Title level={4} underline>
          {translation.startQuestionnaire[lang]}
        </Title>
      }
      onCancel={onCancel}
      footer={null}
    >
      <Spin tip={translation.loader1[lang]} spinning={loading}>
        <Box mt={16}>
          <Space direction="vertical" className="space">
            {contractsData.map((contract, index) => (
              <Box key={contract.contractId} className="contract-section">
                <Text strong>{`Договір №${contract.i}`}</Text>

                <Box>
                  <Text strong>{translation.QuestionnaireArchive2[lang]}</Text>
                  <Text>({translation.questionnaireArchiveText[lang]})</Text>
                  <SearchSelect
                    allowClear
                    list={DECIMAL_RATING_TO_CLIENT.map((i) => ({ value: i, label: i })).reverse()}
                    value={contract.sq}
                    placeholder={translation.questionnaireArchivePlaceholder[lang]}
                    onChange={(item) => handleSelectChange(Number(item), 'sq', index)}
                    name="rate"
                    valueName="value"
                    labelName="label"
                  />
                </Box>

                <Box>
                  <Text strong>{translation.QuestionnaireArchive4[lang]}</Text>
                  <SearchSelect
                    allowClear
                    list={LOGICAL_STANDARD_CHOISE_4_OPTIONS}
                    value={contract.sc}
                    placeholder={translation.placeholderChooseOption[lang]}
                    onChange={(item) => handleSelectChange(item, 'sc', index)}
                    name="communication-satisfaction"
                  />
                </Box>

                <Box>
                  <Text strong>{translation.QuestionnaireArchive7[lang]}</Text>
                  <SearchSelect
                    allowClear
                    list={LOGICAL_STANDARD_CHOISE_4_OPTIONS}
                    value={contract.ts}
                    placeholder={translation.placeholderChooseOption[lang]}
                    onChange={(item) => handleSelectChange(item, 'ts', index)}
                    name="trust"
                  />
                </Box>

                <Box>
                  <Text strong>{translation.QuestionnaireArchive8[lang]}</Text>
                  <Text>({translation.QuestionnaireArchive9[lang]})</Text>
                  <TextArea
                    placeholder={translation.QuestionnaireArchive10[lang]}
                    aria-label="Write proposals and complaints"
                    value={contract.pc}
                    onChange={(e) => handleTextChange(e, index)}
                    status={desError}
                    autoSize
                  />
                </Box>
              </Box>
            ))}
          </Space>
        </Box>
        <Box className="group-btn-questionnaire">
          <Button className="btn-questionnaire" onClick={onSubmit}>
            {translation.sendButton[lang]}
          </Button>
          <Button variant="danger" className="btn-questionnaire" onClick={onCancel}>
            {translation.btnCancelQuestionnaire[lang]}
          </Button>
        </Box>
      </Spin>
    </Modal>
  );
};

export default QuestionnaireInterimServiceQualityAssessment;
