import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import InviteFriendImage from '../../images/invite-friend.webp';

const InviteAdvert = () => {
  const lang = useSelector((state) => state.common.language);

  return (
    <div className="invite-advert">
      <p className="text">{translation.inviteShareLinkText[lang]}</p>
      <p className="description">{translation.inviteShareLinkDescription[lang]}</p>
      <div className="image-wrapper">
        <img src={InviteFriendImage} />
      </div>
    </div>
  );
};

export default InviteAdvert;
