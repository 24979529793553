import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Button from '../Button';
import Row from '../Row/Row';
import Typography from '../Typography/Typography';
import Card from '../Card/Card';
import dayjs from 'dayjs';
import { SendOutlined, WhatsAppOutlined } from '@ant-design/icons';

import { Box, NiceBtn } from 'common_components';
import {
  POSLUGI,
  old_map,
  PHONE_OFFICE_VIEW_CRM,
  PHONE_OFFICE_VIEW_CLIENT,
  TELEGRAM_CHAT_OFFICE_VIEW_CRM,
  VIBER_CHAT_OFFICE_VIEW_CRM,
} from 'common_constants/business';
import { PAYMENT_FRAME } from 'common_constants/modals';
import { WEB_SITE_LINK, CLIENT_LINK, APP_LINK_ANDROID, APP_LINK_IOS } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import { setModal } from '../../store/commonReducer';
import appstoreImg from '../../images/appstore-app.svg';
import googleplayImg from '../../images/googleplay-app.svg';
import EAdvokat from '../../images/e-advokat.png';
import reactNativeService from '../../tools/reactNativeService';

import './HotlineMeetInvite.scss';

const HotlineMeetInvite = ({ crm, filii = [] }) => {
  const dispatch = useDispatch();
  const { hash } = useParams();

  const meetData = useSelector((state) => state.common.hotlineMeetData);
  const lang = useSelector((state) => state.common.language);
  const filData = filii?.find((item) => item?.i === +meetData?.fil) || {};

  const { Text } = Typography;

  const [indexRemove, indexInfoCent] = ['3', '6'];
  const [isRemove, isInfoCent, isVip, isСity] = [meetData.type === indexRemove, meetData.type === indexInfoCent, meetData.vip, filData.name];
  const [orRemoveHotline, allOfficeHotline] = [isRemove || isInfoCent, !isRemove && !isInfoCent];

  const [textRemoveMaybeVip, textOfficeMaybeCities] = [
    `${translation.constHotlineMeetInviteText1_1[lang]} ${
      isVip ? translation.constHotlineMeetInviteText1_2[lang] : translation.constHotlineMeetInviteText1_3[lang]
    }`,
    isСity ? `${translation.constHotlineMeetInviteText1_4[lang]} ${isСity} ` : '',
  ];
  // Інфо центр для клієнта відображається лише як дистанційна консультація
  // Vip буває лише в дист консультаціях, що дає клієнту приставку до дист - Преміум
  const textTypeHotline = orRemoveHotline ? textRemoveMaybeVip : POSLUGI[meetData.type];

  const [lawyerTitle, locationInfo] = [
      isVip ? translation.constHotlineMeetInviteText2_1[lang] : translation.constHotlineMeetInviteText2_2[lang], //* only dynamic vip
      allOfficeHotline
        ? `${textOfficeMaybeCities}${translation.constHotlineMeetInviteText2_3[lang]}` //* only office
        : `${translation.constHotlineMeetInviteText2_4[lang]} ${textRemoveMaybeVip}`, //* only remove + dynamic vip
    ],
    dynamicTextTypeAndTime = (
      <>
        <Text children={`${lawyerTitle} ${translation.constHotlineMeetInviteTitle[lang]} ${locationInfo}`} />
        {meetData?.bmm ? (
          <Link to={`/cv/${meetData.bmm}`}>
            <Text className="lawyer-cv-link">{translation.constHotlineMeetInviteCVTitle[lang]}</Text>
          </Link>
        ) : null}
        <h2 className="time-meet">
          {dayjs(meetData.date).format('DD.MM.YYYY')} о {meetData.startT}.
        </h2>
        <h3>
          {translation.on[lang]} {textTypeHotline}{' '}
        </h3>
      </>
    );

  const handleClickModalPaymentButton = () => {
    window.location = meetData.paymentUrl;
  };

  const balance = meetData.amr && meetData.amr === '2' ? meetData.balance : meetData.balance - meetData.cost;

  return (
    <Card className="hotline-meet-invite">
      {/* pay button */}
      {!meetData.free && (
        <Row justify={'center'}>
          {meetData.paymentUrl && balance < 0 ? (
            <Button onClick={handleClickModalPaymentButton}>{translation.payBtn[lang]}</Button>
          ) : (
            <Row style={{ fontWeight: 'bold', marginBottom: 10 }}>{translation.thanksForTheOpal[lang]}</Row>
          )}
        </Row>
      )}
      {/*  */}
      {translation.hotlineMeetInviteCardTitle[lang]}
      <br />
      {translation.hotlineMeetInviteCardText1[lang]}
      <br />№ {meetData.i} {translation.hotlineMeetInviteCardText2[lang]} {dayjs(meetData.date).format('DD.MM.YYYY')}.<br />
      <br />
      {meetData.name}
      <br />
      {dynamicTextTypeAndTime}
      {allOfficeHotline && (
        <>
          <h3>
            {' '}
            {translation.clientContactUsInfo1[lang]} {filData.address}
          </h3>
          {translation.filiiState[lang]} {filData.address_description}
          <br />
          {filData.video && (
            <iframe
              width="100%"
              height="315"
              src={filData.video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          <br />
          <br />
        </>
      )}
      {translation.hotlineMeetInviteCardText3[lang]}
      <br />
      <br />
      {allOfficeHotline && (
        <>
          {translation.hotlineMeetInviteCardText4[lang]}
          <br />- {translation.hotlineMeetInviteCardText5[lang]}
          <br />- {translation.hotlineMeetInviteCardText6[lang]}
          <br />
        </>
      )}
      <Box
        style={{
          textAlign: 'center',
          margin: '24px auto 8px',
        }}
      >
        <a rel="noopener noreferrer" target="_blank" href={CLIENT_LINK}>
          <NiceBtn> {meetData.chn ? translation.hotlineMeetInviteCardNiceBtn1[lang] : translation.hotlineMeetInviteCardNiceBtn2[lang]}</NiceBtn>
        </a>
      </Box>
      <br />
      {meetData.chn ? translation.hotlineMeetInviteCardMeetData1[lang] : translation.hotlineMeetInviteCardMeetData2[lang]}
      <br />
      <br />
      {translation.hotlineMeetInviteCardText7[lang]}
      <br />
      {translation.hotlineMeetInviteCardText8[lang]}
      <br />
      <br />
      {translation.hotlineMeetInviteCardText9[lang]}
      <br />
      <a href={PHONE_OFFICE_VIEW_CRM}>{PHONE_OFFICE_VIEW_CLIENT}</a>
      <br />
      <br />
      {translation.hotlineMeetInviteCardText10[lang]}
      <div>
        <span>
          <a rel="noopener noreferrer" target="_blank" href={TELEGRAM_CHAT_OFFICE_VIEW_CRM}>
            <SendOutlined />
            &nbsp;{translation.telegram[lang]}
          </a>
          &nbsp;та&nbsp;
          <a rel="noopener noreferrer" target="_blank" href={VIBER_CHAT_OFFICE_VIEW_CRM}>
            <WhatsAppOutlined />
            &nbsp;{translation.viber[lang]}
          </a>
        </span>
      </div>
      <br />
      {translation.hotlineMeetInviteCardText11[lang]}
      <br />
      <a rel="noopener noreferrer" target="_blank" href={WEB_SITE_LINK}>
        {WEB_SITE_LINK}
      </a>
      <br />
      <div className="eadvokat-wrapper">
        <div className="eadvokat-image-wrapper">
          <img
            className="eadvokat-image"
            src={EAdvokat}
            alt={translation.eAdvokatImageDescription[lang]}
            title={translation.eAdvokatImageDescription[lang]}
          />
        </div>
        <div>
          <div className="eAdvokat-logo-image-wrapper">
            <img alt="Logo_Image" src={'/logo.svg'}></img>
          </div>
          <h2>{translation._appTitle[lang]}</h2>
          <br />
          <p>{translation.yourCabinet[lang]}</p>
          <a rel="noopener noreferrer" target="_blank" href={CLIENT_LINK}>
            {CLIENT_LINK}
          </a>
          <br />
          <br />
          {translation.appForSmartphones[lang]}
          <br />
          <br />
          <div className="app-download">
            <a rel="noopener noreferrer" target="_blank" href={APP_LINK_ANDROID}>
              <div>
                <img className="app-download-link" src={googleplayImg} alt="googleplay" />
              </div>
            </a>
            <a rel="noopener noreferrer" target="_blank" href={APP_LINK_IOS}>
              <div>
                <img className="app-download-link" src={appstoreImg} alt="appstore" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <br />
      {translation.hotlineMeetInviteCardText12[lang]}
      <br />
      <br />
      {translation.hotlineMeetInviteCardText13[lang]}
      <br />
      {crm ? (
        <>
          <a href={old_map}>{old_map}</a>
          <br />
          <br />
          {!meetData.m && <Button onClick={() => crm.onСonfirmSending(meetData)}>{translation.hotlineMeetInviteCardButton1[lang]}</Button>}
          <br />
          {!meetData.m && (
            <Button className="viber" onClick={() => crm.onСonfirmViber(meetData)}>
              {translation.hotlineMeetInviteCardButton2[lang]}
            </Button>
          )}
        </>
      ) : (
        <iframe
          title="referral for a consultation on the map"
          src="https://www.google.com/maps/d/embed?mid=1ZD_4qum2zZc_zWFjcUnI75zD0EGzYkLf&hl=ru&ehbc=2E312F"
          width="100%"
          height="480"
        ></iframe>
      )}
    </Card>
  );
};

export default HotlineMeetInvite;
