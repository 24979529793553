import dayjs from 'dayjs';
import { PAY_PURPOSES, SERVICE_PAYMENT_TYPE, CURRENCIES, CASHBACK_30_START_DATE, CASHBACK_OPEN_TYPES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { useDispatch, useSelector } from 'react-redux';
import MandatoryConfirmationEmail from '../../../components/MandatoryConfirmationEmail';

import './Transactions.scss';
import onBalance from '../../../images/on-balance.svg';
import fromBalance from '../../../images/from-balance.svg';
import { request } from '../../../tools';
import { CheckClientInfoOnly, useToaster } from '../../../tools/hooks';
import { useState } from 'react';
import clsx from 'clsx';

const PAYFOR_SUGAR = '51';

const Transactions = ({ transactions, bonusesTransactions, sugarTransactions, insuranceTransactions, insuranceType, refetch }) => {
  return (
    <>
      {transactions && <BalanceTransactions transactions={transactions} refetch={refetch} />}
      {bonusesTransactions && <BonusesTransactions transactions={bonusesTransactions} />}
      {sugarTransactions && <SugarTransactions transactions={sugarTransactions} />}
      {insuranceTransactions && <InsuranceTransactions transactions={insuranceTransactions} insuranceType={insuranceType} />}
    </>
  );
};

const BonusesTransactions = ({ transactions = [] }) => {
  const lang = useSelector((state) => state.common.language);
  const getTransactionText = (transaction) => {
    if (transaction?.b + transaction?.p === 0) {
      return translation.transactionsCashbackForBonuses[lang];
    } else if (transaction?.b > 0) {
      return translation.transactionsAccrualBonuses[lang];
    } else {
      return;
    }
  };
  return transactions.map((transaction, i) => (
    <div className="transactions-item" key={i}>
      <div className="transactions-item-operation">
        <div className="transactions-item-icon">
          <img src={transaction?.b > 0 ? onBalance : fromBalance} alt="balance" />
        </div>
        <div>
          {transaction.de ? (
            <span className="error">{translation.transactionsRejected[lang]}</span>
          ) : (
            !transaction.a && translation.transactionsPending[lang]
          )}
          <div className="transactions-item-operation-text">{getTransactionText(transaction)}</div>
          <div className="transactions-item-operation-date">{dayjs(transaction?.T).format('DD MMM YYYYр')}</div>
        </div>
      </div>
      <div className="transactions-item-value" style={{ color: transaction.b < 0 ? 'red' : '#46CA30' }}>
        {transaction?.b < 0 ? `-₴${Math.abs(transaction?.b)}` : `₴${transaction.b}`}
      </div>
    </div>
  ));
};

const BalanceTransactions = ({ transactions = [], refetch }) => {
  const dispatch = useDispatch();
  const { success, error } = useToaster();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const isEmailVerified = clientAuth?.emailVerified;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const moreMonthHasPassed = dayjs(clientInfo.createdAt).isBefore(oneMonthAgo);

  const return30Percents = (transaction) => {
    setLoading(true);
    const body = {
      clientId: clientInfo._id,
      transactionIndex: transaction.i,
      amount: (transaction.amount / 100) * 30,
    };
    const onSuccess = () => {
      setTimeout(() => {
        CheckClientInfoOnly(dispatch);
        refetch();
        success('Успішно');
        setLoading(false);
      }, 1000);
    };
    const onError = () => {
      error('Щось пішло не так');
      setLoading(false);
    };
    request('/payment/exchangeBonusesToMoney', body, onSuccess, onError);
  };

  const collectBonusClick = (transaction) => {
    if (moreMonthHasPassed && !isEmailVerified) {
      setEmailVerified(true);
      return;
    }
    if (!loading) {
      return30Percents(transaction);
    }
  };
  return transactions.map((transaction, i) => {
    const isHaveEnoughBonusesToReturn30Percents = clientInfo.B - (transaction.amount / 100) * 30 > 0;
    const isEligibleForReturn =
      transaction?.i &&
      transaction?.sugarId &&
      !transaction?.isCashbackReceived &&
      transaction.date &&
      dayjs(transaction.date).isAfter(dayjs(CASHBACK_30_START_DATE)) &&
      dayjs(transaction.date).isAfter(dayjs().subtract(1, 'month')) &&
      CASHBACK_OPEN_TYPES.includes(transaction?.type) &&
      isHaveEnoughBonusesToReturn30Percents;
    return (
      <div className="transactions-item" key={i}>
        <div className="transactions-item-operation">
          <div className="transactions-item-icon">
            <img src={transaction?.order_id || [9, 10, 13, 17, 22].includes(transaction?.type) ? onBalance : fromBalance} alt="balance" />
          </div>
          <div>
            <div className="transactions-item-operation-text">
              {transaction?.type ? SERVICE_PAYMENT_TYPE?.find((s) => s.value === transaction?.type)?.label : translation.replenishmentBalance[lang]}
            </div>
            <div className="transactions-item-operation-date">
              {transaction?.date ? dayjs(transaction?.date).format('DD MMM YYYYр') : dayjs(transaction?.order_time).format('DD MMM YYYYр')}
            </div>
            {isEligibleForReturn && (
              <div
                className={clsx('transactions-item-return-button', {
                  disabled: loading,
                })}
                onClick={() => collectBonusClick(transaction)}
              >
                {translation.return30Percents[lang]}
              </div>
            )}
            {emailVerified && moreMonthHasPassed && !isEmailVerified && (
              <div className="MandatoryConfirmationEmail-wrapper">
                {' '}
                <MandatoryConfirmationEmail text={translation.bonusRetrieval[lang]} />{' '}
              </div>
            )}
          </div>
        </div>
        <div
          className="transactions-item-value"
          style={{
            color: !transaction?.type || [9, 10, 13, 17, 19, 22].includes(transaction?.type) ? '#46CA30' : 'red',
          }}
        >
          {!transaction?.type || [9, 10, 13, 17, 19, 22].includes(transaction?.type)
            ? `${transaction.currency ? CURRENCIES[transaction.currency]?.symbol || '₴' : '₴'}${transaction?.amount}`
            : `-${transaction.currency ? CURRENCIES[transaction.currency]?.symbol || '₴' : '₴'}${Math.abs(transaction?.amount)}`}
        </div>
      </div>
    );
  });
};

const SugarTransactions = ({ transactions = [] }) => {
  const lang = useSelector((state) => state.common.language);
  const filteredTransactions = transactions.filter((transaction) => transaction.payfor !== PAYFOR_SUGAR);

  const duplicatedTransactions = filteredTransactions.flatMap((transaction) => [
    { ...transaction, operationType: translation.replenishmentBalance[lang] },
    {
      ...transaction,
      operationType: !['3', '2', '5'].includes(transaction.payfor) ? translation.paymenttoContract[lang] : PAY_PURPOSES[transaction.payfor]?.label,
      isPayFor: true,
    },
  ]);

  return duplicatedTransactions.map((transaction, i) => (
    <div className="transactions-item" key={i}>
      <div className="transactions-item-operation">
        <div className="transactions-item-icon">
          <img src={transaction?.operationType === translation.replenishmentBalance[lang] ? onBalance : fromBalance} alt="balance" />
        </div>
        <div>
          <div className="transactions-item-operation-text">
            {transaction.operationType === translation.replenishmentBalance[lang]
              ? translation.replenishmentBalance[lang]
              : transaction.operationType || translation.unknownOperation[lang]}
          </div>
          <div className="transactions-item-operation-date">{transaction?.payday ? dayjs(transaction?.payday).format('DD MMM YYYYр') : '-'}</div>
        </div>
      </div>
      <div className="transactions-item-value" style={{ color: transaction.isPayFor ? 'red' : '#46CA30' }}>
        {`${transaction.isPayFor ? '-₴' : '₴'}${Math.abs(transaction.amount)}`}
      </div>
    </div>
  ));
};

const InsuranceTransactions = ({ transactions = [], insuranceType }) => {
  return transactions.map((transaction, i) => (
    <div className="transactions-item" key={i}>
      <div className="transactions-item-operation">
        <div className="transactions-item-icon">
          <img src={fromBalance} alt="balance" />
        </div>
        <div>
          <div className="transactions-item-operation-text">{`${insuranceType?.services[transaction.type]?.name} - ${
            transaction?.multiplier
          } год.`}</div>
          <div className="transactions-item-operation-date">{dayjs(transaction?.date).format('DD MMM YYYYр')}</div>
        </div>
      </div>
      <div className="transactions-item-value" style={{ color: 'red' }}>
        -₴{Math.abs(transaction?.price * transaction?.multiplier)}
      </div>
    </div>
  ));
};

export default Transactions;
