import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { List } from '../../components';

import { request, error } from '../../tools';
import ModalWindow from '../ModalWindow';
import ContractTaskItem from './ContractTaskItem/ContractTaskItem';
import { editContracts, addNewMessageToContractChat } from '../../store/commonReducer';
import { translation } from 'common_constants/translation';

import './styles.scss';

const ContractTasks = ({ open, setOpen, contractId }) => {
  const dispatch = useDispatch();

  const contracts = useSelector((state) => state.common.contracts);

  const lang = useSelector((state) => state.common.language);

  const ITEMS_PER_PAGE = 10;

  const [currentTasks, setCurrentTasks] = useState([]);

  const [deadline, setDeadline] = useState(null);
  const [title, setTitle] = useState('');

  const onCancel = () => setOpen(false);

  useEffect(() => {
    const contract = contracts.find((item) => item._id === contractId);
    const sortedTasks = contract?.tasks
      ? contract?.tasks
          .map((task) => task)
          .sort((task) => (task?.done ? 1 : -1))
          .sort((task) => (task?.rejected ? 1 : -1))
      : [];
    setCurrentTasks(sortedTasks);
  }, [contractId]);

  const rejectTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'rejected' });
  };

  const setDoneTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'done' });
  };

  const onChangeInputValue = ({ value, index, field }) => {
    const updatedTasks = currentTasks.map((item, itemIndex) => (itemIndex === index ? { ...item, [field]: value } : item));
    setCurrentTasks(updatedTasks);
    saveData(updatedTasks);
  };

  const addCreatingTask = () => {
    const formattedDeadline = deadline ? new Date(deadline).toISOString() : null;

    const newTask = {
      deadline: formattedDeadline,
      title,
    };

    const updatedTasks = [...currentTasks, newTask];

    setCurrentTasks(updatedTasks);
    setDeadline(null);
    setTitle('');
    saveData(updatedTasks);
  };

  const saveData = (tasks) => {
    const requestData = {
      tasks: tasks.length > 0 ? tasks : currentTasks,
      _id: contractId,
    };

    request(
      '/contracts/addTasks',
      requestData,
      ({ status, newChatItem }) => {
        if (status) {
          dispatch(editContracts(requestData));
          if (newChatItem) {
            dispatch(addNewMessageToContractChat({ chatId: contractId, data: newChatItem }));
          }
        }
      },
      error,
    );
  };

  return (
    <ModalWindow modal={open} title={translation.contractTasksTitle2[lang]} setModal={onCancel} footer={null} className="tasksModal">
      {open && (
        <div className="tasks">
          <List
            pagination={{
              pageSize: ITEMS_PER_PAGE,
              style: { justifyContent: 'center', marginTop: 15 },
            }}
            dataSource={currentTasks}
            renderItem={(item, index, page) => {
              return (
                <ContractTaskItem
                  item={item}
                  key={index}
                  index={index}
                  page={page}
                  ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                  rejectTask={rejectTask}
                  setDoneTask={setDoneTask}
                  onChangeInputValue={onChangeInputValue}
                  lang={lang}
                />
              );
            }}
          >
            <div className="task-item">
              <div className="task-item-date create">
                <input type="date" value={deadline || ''} onChange={(e) => setDeadline(e.target.value)} className="form-input" />
              </div>

              <div className="row-bottom">
                <div className="form-group">
                  <input
                    value={title || ''}
                    className="form-input _title"
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={translation.contractTasksInputPlaceholder[lang]}
                  />
                </div>
              </div>
            </div>

            <div className="add-new-task">
              <button className={`${!title ? 'disabled' : ''}`} disabled={!title} onClick={addCreatingTask}>
                <PlusOutlined /> <p>{translation.contractTasksText3[lang]}</p>
              </button>
            </div>
          </List>
        </div>
      )}
    </ModalWindow>
  );
};

export default ContractTasks;
