import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setHotlines } from '../../../store/commonReducer';

import Col from '../../../components/Col';
import dayjs from 'dayjs';
import { POSLUGI, NOTIFICATION } from 'common_constants/business';

import { translation } from 'common_constants/translation';
import { CLIENT_ROUTES } from 'common_constants/routes';
import Empty from '../../../images/empty-hotlines.svg';
import { useLocation } from 'react-router-dom';

import ClientMainPageHotlineCert from './ClientMainPageHotlineCert';
import ClientMainPageHotlineItem from './ClientMainPageHotlineItem';
import './ClientMainPageHotlines.scss';
import { fetchRemoveNotifications } from '../../ClientContracts/ClientContractsHelpers';
import ThanksForLawyer from '../../../Modals/ThanksForLawyer';

const ClientMainPageHotlines = ({ clientHotlines, hotlinesLoaded, certHotline, clientHistoryHotlines, paymentByClubCard }) => {
  const dispatch = useDispatch();
  const [lang, clientInfo, filii, hotlinesSelect] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.clientInfo) ?? [],
    useSelector((state) => state.common.filii),
    useSelector((state) => state.common.hotlines) ?? [],
  ];
  const [hotlineId, setHotlineId] = useState(null);
  const [open, setOpen] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const hotlineInProcessIndex = searchParams.get('paid-for');

  const hotlines = useMemo(() => {
    const formatHotlines = (hotlines, hotlineType) => {
      if (!hotlines || hotlines.length === 0) return [];

      const removeNotifEventsClick = async () => {
        const [bargeNotificationEventsHotlines, bargeNotificationEventsAssignments] = [
          hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
          hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
        ];

        const bargeNotificationEvents = bargeNotificationEventsHotlines + bargeNotificationEventsAssignments;

        if (bargeNotificationEvents && clientInfo?.ph) {
          const notif = await fetchRemoveNotifications(NOTIFICATION.EVENTS, null, null, clientInfo?.ph);

          if (notif?.hotline) {
            const _hotlines =
              hotlinesSelect?.map(
                (ho) => (ho = { ...ho, notif: Array.isArray(ho.notif) ? [...ho.notif, { watched: true }] : [{ watched: true }] }),
              ) ?? [];

            dispatch(setHotlines(_hotlines));
          }
        }
      };

      removeNotifEventsClick();
      return hotlines
        .slice()
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((item) => ({
          ...item,
          formattedDate: dayjs(item.date).format('DD.MM.YYYY'),
          filName: filii?.find((fil) => fil?.i === +item?.fil)?.name,
          address: filii?.find((fil) => fil?.i === +item?.fil)?.address,
          hotlineType,
        }));
    };

    const formattedCurrent = formatHotlines(clientHotlines?.flat(), 'CURRENT');
    const formattedHistory = formatHotlines(clientHistoryHotlines, 'HISTORY');
    return { current: formattedCurrent, history: formattedHistory };
  }, [clientHotlines, clientHistoryHotlines, filii]);

  return (
    <Col className="clientMain-hotlines">
      {certHotline?.[0]?.[0]?.cert > 0 && <ClientMainPageHotlineCert chn={certHotline?.[0]?.[0]?.cert} />}
      {hotlinesLoaded && !hotlines.current.length && (
        <div className="client-hotlines-empty">
          <h3>{translation.noConsultation[lang]}</h3>
          <img src={Empty} alt="Hotlines empty logo}" />
          <Link to={CLIENT_ROUTES.HOTLINE.route} className="client-hotlines-empty__btn">
            {translation.consultationButton[lang]}
            <div className="menu-item-text-arrow"></div>
          </Link>
        </div>
      )}
      {hotlinesLoaded &&
        hotlines.current.map((item) => {
          const hasViewedNotifications = !(item.notif?.some((notifItem) => notifItem.watched) ?? false);
          return (
            <ClientMainPageHotlineItem
              key={item._id}
              fil={item.filName}
              address={item.address}
              type={POSLUGI[item.type]}
              startT={item.startT}
              endT={item.endT}
              statusOfMeet={item?.s}
              transactions={item?.transactions}
              date={item.formattedDate}
              hotlineType={item.hotlineType}
              consultant={item.amm?.[0] ?? item.bmm?.[0]}
              paymentUrl={item?.paymentUrl}
              cost={item?.cost}
              qh={item?.qh}
              s={item?.s}
              H={item._id}
              bmm={item?.bmm}
              amm={item?.amm}
              chn={item.chn}
              setOpen={setOpen}
              isPending={!item.author}
              hasViewedNotifications={hasViewedNotifications}
              setHotlineId={setHotlineId}
              inProcess={hotlineInProcessIndex == item.i}
              i={item.i}
              id={item._id}
              vip={item?.vip}
              clubCard={clientInfo.clubCard}
              paymentByClubCard={paymentByClubCard}
              isPaidByClubCard={item.paymentByClubCard}
              isMoneyOnBalance={clientInfo.clientBalance > item?.cost}
            />
          );
        })}

      {hotlinesLoaded &&
        hotlines.history.map((item) => {
          const hasViewedNotifications = !(item.notif?.some((notifItem) => notifItem.watched) ?? false);
          return (
            <ClientMainPageHotlineItem
              key={item._id}
              fil={item.filName}
              address={item.address}
              type={POSLUGI[item.type]}
              startT={item.startT}
              endT={item.endT}
              statusOfMeet={item?.s}
              transactions={item?.transactions}
              date={item.formattedDate}
              hotlineType={item.hotlineType}
              consultant={item.amm?.[0] ?? item.bmm?.[0]}
              paymentUrl={item?.paymentUrl}
              cost={item?.cost}
              qh={item?.qh}
              s={item?.s}
              H={item._id}
              bmm={item?.bmm}
              chn={item.chn}
              setOpen={setOpen}
              hasViewedNotifications={hasViewedNotifications}
              setHotlineId={setHotlineId}
              id={item._id}
              isMoneyOnBalance={clientInfo.clientBalance > item?.cost}
            />
          );
        })}
      <ThanksForLawyer
        open={open}
        setOpen={setOpen}
        arrayPayment={[50, 100, 150, 200]}
        clientId={clientInfo._id}
        ph={clientInfo.ph}
        title={translation.tipsToTheLawyerTitle[lang]}
        placeholder={translation.clientMainPageHotlinesModalPlaceholder[lang]}
        buttonText={translation.clientBalancePayModalButtonText[lang]}
        paymentType={'thanksLawyer'}
        hotlineId={hotlineId}
      />
    </Col>
  );
};

export default ClientMainPageHotlines;
