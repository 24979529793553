import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/uk';

import Image from '../../components/Image';
import { Statistic } from '../../components/Statistic';
import { request, error } from '../../tools/';
import CourtPracticeItem from './CourtPracticeItem';
import AchievementItem from './AchievementItem';

import './LawyerCV.scss';

dayjs.locale('uk');
dayjs.extend(relativeTime);

function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

function getWorkExperience(startWorkDate) {
  const startDateWithExperience = dayjs(startWorkDate);
  const updatedEndDate = dayjs().add(3, 'year');
  const durationInMonths = updatedEndDate.diff(startDateWithExperience, 'month');
  const years = Math.floor(durationInMonths / 12);
  const months = durationInMonths % 12;

  let result = years === 3 || years === 4 ? `${years} роки` : `${years} років`;

  if (months > 0) {
    if (months === 1) {
      result += ` 1 місяць`;
    } else if (months >= 2 && months <= 4) {
      result += ` ${months} місяці`;
    } else {
      result += ` ${months} місяців`;
    }
  }

  return result;
}

function getNumberOfWonCases(startWorkDate) {
  const now = dayjs();
  return now.diff(startWorkDate, 'week');
}

function getNumberOfConsultations(startWorkDate) {
  const now = dayjs();
  return now.diff(startWorkDate, 'day');
}

function getAddressOfWork(filii, fil) {
  const address = filii?.find((item) => item.i == fil)?.address;
  return address || '';
}

const LawyerCV = () => {
  const { id: lawyerId } = useParams();
  const filii = useSelector((state) => state.common.filii);
  const lang = useSelector((state) => state.common.language);

  const [currentCV, setCurrentCV] = useState();
  const [loading, setLoading] = useState(true);

  const getUser = () => {
    setLoading(true);
    request(
      `/profile/getUserCV/${lawyerId}`,
      {},
      ({ user }) => {
        setCurrentCV(user[0]);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    getUser();
  }, [lawyerId]);

  useEffect(() => {
    const onResize = () => {
      const element = document.querySelector('.lawyer-info');
      if (element) {
        if (window.innerWidth > 600) element.style.transform = null;
        else element.style.transform = `scale(${window.innerWidth / 600})`;
      }
    };
    window.addEventListener('resize', onResize);
    onResize();
  }, []);

  return (
    <section className="lawyer-cv_page">
      <div className="lawyer-info">
        {currentCV?.upa ? (
          <div>
            <Image src={process.env.REACT_APP_API + '/avatars/' + currentCV.upa + '.jpeg'} />
          </div>
        ) : null}
        <h1 className="name">{currentCV?.name}</h1>
        <div className="about-wrapper">
          <div>
            {/* <p className="position-lawyer">{currentCV?.lawyerStatus ? translation.positionLawyer1[lang] : translation.positionLawyer2[lang]}</p> */}
            <div className="wrapper">
              {currentCV?.jobPosition ? (
                <div className="item">
                  <h6>{translation.lawyerCVPoints1[lang]}</h6>
                  <p>{currentCV?.jobPosition}</p>
                </div>
              ) : null}
              {currentCV?.education ? (
                <div className="item">
                  <h6>{translation.lawyerCVPoints2[lang]}</h6>
                  <p>{currentCV.education}</p>
                </div>
              ) : null}
              {currentCV?.startWorkDate ? (
                <div className="item">
                  <h6>{translation.lawyerCVPoints3[lang]}</h6>
                  <p>{getWorkExperience(currentCV.startWorkDate)}</p>
                </div>
              ) : null}
              {currentCV?.workPhone ? (
                <div className="item">
                  <h6>{translation.lawyerCVPoints4[lang]}</h6>
                  <p>{formatPhoneNumber(currentCV.workPhone)}</p>
                </div>
              ) : null}
            </div>
            <div className="address">
              <p>{translation.lawyerCVPoints5[lang]}</p>
              <address>{getAddressOfWork(filii, currentCV?.fil)}</address>
            </div>
          </div>
          <div className="summary">
            <Statistic title={translation.successfulCases[lang]} value={getNumberOfWonCases(currentCV?.startWorkDate)} loading={loading} />
            <Statistic title={translation.consultationsCounter[lang]} value={getNumberOfConsultations(currentCV?.startWorkDate)} loading={loading} />
          </div>
        </div>

        <div className="additional-info-wrapper">
          {currentCV?.extraEducation ? (
            <div>
              <h5>{translation.lawyerCVPoints6[lang]}</h5>
              {currentCV.extraEducation.map((item, index) => (
                <div className="underline additional-education-item" key={index}>
                  <b>{item.name}</b>
                  <p className="additional-education-item-location">{item.location}</p>
                  <p className="additional-education-item-result">{item.result}</p>
                </div>
              ))}
            </div>
          ) : null}
          {currentCV?.achievements ? (
            <div className="col-item _bottom">
              <h5>{translation.lawyerCVPoints7[lang]}</h5>
              {currentCV.achievements.map((item) => (
                <AchievementItem item={item} key={item._id} lawyerId={lawyerId} />
              ))}
            </div>
          ) : null}
          {currentCV?.practice ? (
            <div>
              <h5>{translation.lawyerCVPoints8[lang]}</h5>
              <div className="practice-items-wrapper">
                {currentCV.practice.map((item) => (
                  <CourtPracticeItem key={item._id} item={item} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default LawyerCV;
